import './global.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { ANALYTICS_ID, ENV, SENTRY_DSN, SENTRY_ENABLED, SENTRY_ENV } from './constants/common';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './utils/reportWebVitals';
import Layout from './components/Layout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Index from '.';
import ErrorPage from './pages/ErrorPage';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { theme } from './constants/themes';
import { ToastContainer } from 'react-toastify';
import useSiteOrigin from './hooks/useSiteOrigin';

try {
  ReactGA.initialize(ANALYTICS_ID, {
    gaOptions: {
      forceSSL: true,
    },
  });
} catch (e) {}

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENABLED,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
});

const appRouter = createBrowserRouter([
  {
    path: '/',
    Component: () => <>App...</>,
  },
]);

const landingRouter = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Index />,
      },
      {
        path: '/dashboard',
        Component: () => <>Dashboard...</>,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

const App = () => {
  const origin = useSiteOrigin();
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.querySelector("meta[name='language']")?.setAttribute('content', i18n.language);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={origin === 'landing' ? landingRouter : appRouter} />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Could not get 'root' element");
else createRoot(rootElement).render(<App />);

reportWebVitals();

if (ENV === 'dev' || ENV === 'prod') serviceWorker.register();
