import base from './_base';

const baseTranslations: typeof base = {
  header: {
    menu: {
      buttons: {
        gettingStarted: {
          label: 'Getting Started',
        },
        about: {
          label: 'About',
        },
      },
    },
  },
  footer: {
    privacyPolicy: '',
  },
  components: {
    locationDropdowns: {
      country: {
        label: '',
      },
      state: {
        label: '',
      },
      city: {
        label: '',
      },
    },
  },
  landingPage: {
    banner: {
      headline: '',
      buttons: {
        viewReviews: {
          label: '',
        },
        submitReview: {
          label: '',
        },
      },
    },
    callsToAction: {
      headline: '',
      accountability: {
        heading: '',
        body: '',
      },
      transparency: {
        heading: '',
        body: '',
      },
      raiseStandards: {
        heading: '',
        body: '',
      },
    },
  },
  reviewsPage: {
    filters: {
      show: '',
      hide: '',
      filters: '',
      postal: {
        label: '',
      },
      landlord: {
        label: '',
      },
    },
    reviewBox: {
      labels: {
        repair: '',
        stability: '',
        privacy: '',
        respect: '',
      },
      landlordAverage: '',
      errorFetchingHeader: '',
    },
  },
};

export default baseTranslations;
