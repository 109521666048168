export type MenuLink = { label: string } & (
  | { link: string }
  | { action: () => void }
  | { submenu: MenuLink[] }
);

export const useMenuConfig = () => {
  const buttons: MenuLink[] = [
    {
      label: 'gettingStarted',
      link: '/getting-started',
    },
    {
      label: 'about',
      link: '/about',
    },
  ];

  return { buttons };
};
