import { useState, useEffect } from 'react';

const useSiteOrigin = () => {
  const [origin, setOrigin] = useState<'landing' | 'app'>(
    window.location.host.startsWith('app') ? 'app' : 'landing'
  );

  const listenToPopstate = () => {
    const winOrigin = window.location.host;
    setOrigin(winOrigin.startsWith('app') ? 'app' : 'landing');
  };

  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);

  return origin;
};

export default useSiteOrigin;
