import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#2596be',
        contrastText: '#FFFFFF',
      },
      action: {
        hover: '#FFCC00',
      },
      text: {
        primary: '#2E294E',
      },
    },
    typography: {
      body1: {
        fontWeight: 100,
      },
      h1: {
        fontSize: '2rem',
      },
      h2: {
        fontSize: '1.5rem',
      },
      h3: {
        fontSize: '1.25rem',
      },
      h4: {
        fontSize: '1.15rem',
      },
      subtitle1: {
        fontSize: '0.75rem',
        color: '#2596be',
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: '#FFFFFF',
        },
      },
    },
  }),
  {
    factor: 2,
    breakpoints: ['xs', 'sm'],
  }
);
