import base from './_base';

const translations: typeof base = {
  header: {
    menu: {
      buttons: {
        gettingStarted: {
          label: 'Getting Started',
        },
        about: {
          label: 'About',
        },
      },
    },
  },
  footer: {
    privacyPolicy: 'Vie Privée',
  },
  components: {
    locationDropdowns: {
      country: { label: 'Pays' },
      state: { label: 'État' },
      city: { label: 'Ville' },
    },
  },
  landingPage: {
    banner: {
      headline: "S'entraider entre locataires en braquant les projecteurs sur les propriétaires",
      buttons: {
        viewReviews: {
          label: 'Voir les critiques',
        },
        submitReview: {
          label: 'Soumettre une critique',
        },
      },
    },
    callsToAction: {
      headline: 'Aider les autres locataires',
      accountability: {
        heading: 'Redevabilité',
        body: 'Faire en sorte que les propriétaires soient responsables de leur comportement',
      },
      transparency: {
        heading: 'Transparence',
        body: "Permettre à d'autres personnes d'être informées des problèmes rencontrés par le passé avec des propriétaires négatifs",
      },
      raiseStandards: {
        heading: 'Relever la Barre',
        body: "Les propriétaires n'ont pas à respecter les normes s'il n'y a pas de conséquences",
      },
    },
  },
  reviewsPage: {
    filters: {
      show: 'Voir',
      hide: 'Cacher',
      filters: 'Filtres',
      postal: {
        label: 'Code postal',
      },
      landlord: {
        label: 'Nom du bailleur',
      },
    },
    reviewBox: {
      labels: {
        repair: 'Réparation',
        stability: 'Stabilité',
        privacy: 'Confidentialité',
        respect: 'Respecter',
      },
      landlordAverage: 'moyenne du bailleur',
      errorFetchingHeader: "Erreur lors de la recherche d'avis",
    },
  },
};

export default translations;
