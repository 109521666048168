import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import { availableLanguages } from '../../i18n/translations';
import NestedHoverMenuItem from './NestedHoverMenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMenuConfig } from './config';
import { useState } from 'react';
import { APP_BASE_URL } from '../../constants/common';

const FullMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buttons } = useMenuConfig();
  const { t, i18n } = useTranslation();

  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<null | HTMLElement>(null);
  const languageMenuOpen = Boolean(languageMenuAnchorEl);

  const handleLanguageIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = (languageCode?: string) => {
    if (languageCode) i18n.changeLanguage(languageCode);
    setLanguageMenuAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: '20px', gap: '15px' }}>
      <Grid
        container
        marginLeft={'auto'}
        width={'inherit'}
        spacing={'15px'}
        flexGrow={0}
        display={'flex'}
      >
        {buttons.map((menulink) => {
          if ('link' in menulink || 'action' in menulink) {
            let action: () => void;
            let variant: 'contained' | 'text' = 'text';

            const { label } = menulink;

            if ('link' in menulink) {
              action = () => navigate(menulink.link);
              variant = pathname?.startsWith(menulink.link) ? 'contained' : 'text';
            } else {
              action = menulink.action;
            }
            return (
              <Grid item my={'auto'}>
                <Button
                  onClick={action}
                  key={label}
                  sx={{
                    display: 'block',
                    textTransform: 'none',
                  }}
                  variant={variant}
                >
                  {t(`header.menu.buttons.${label}.label`)}
                </Button>
              </Grid>
            );
          } else {
            return (
              <Grid item my={'auto'}>
                <NestedHoverMenuItem key={menulink.label} {...menulink} />
              </Grid>
            );
          }
        })}
      </Grid>
      <Box display="flex">
        <a href={APP_BASE_URL}>
          <Button
            variant="contained"
            size="medium"
            sx={{
              display: 'block',
              marginX: '5px',
              marginY: 'auto',
              textTransform: 'none',
            }}
          >
            {t('Start Your Club')}
          </Button>
        </a>

        {availableLanguages.length > 1 && (
          <Button
            variant="text"
            name="Language Select Button"
            onClick={handleLanguageIconClick}
            sx={{
              fontSize: '1.5rem',
              padding: '2px',
              ':hover': { backgroundColor: 'rgba(0, 0, 0, 0)' },
            }}
          >
            <FontAwesomeIcon icon={faLanguage} />
          </Button>
        )}
        <Menu
          id="language-menu"
          anchorEl={languageMenuAnchorEl}
          open={languageMenuOpen}
          onClose={() => handleLanguageMenuClose()}
          MenuListProps={{
            'aria-labelledby': 'language-button',
          }}
        >
          {availableLanguages.map(([languageCode, { nativeName }]) => (
            <MenuItem
              key={`lngmenu-${nativeName}`}
              onClick={() => handleLanguageMenuClose(languageCode)}
            >
              {nativeName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default FullMenu;
